<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="DATOS DE CLIENTE A FACTURAR"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="nombre"
            ><strong>RAZ&Oacute;N SOCIAL: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre"
            v-model.trim="data_cliente_factura.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="ci_nit"><strong>TIPO DOCUMENTO: </strong></label>
          <Dropdown
            id="tipo_documento_identidad"
            v-model="tipo_documento_selected"
            :options="tipos_documentos"
            optionLabel="label"
            placeholder="Seleccione tipo documento..."
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-4">
          <label for="ci_nit"><strong>CI/NIT: </strong></label>
          <InputText id="ci_nit" v-model="data_cliente_factura.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="ci_nit"><strong>EXT. </strong></label>
          <InputText
            id="ci_nit"
            v-model="data_cliente_factura.complemento_documento"
          />
        </div>
        <div class="field col-12 md:col-8">
          <label for="correo"
            ><strong>CORREO ELECTR&Oacute;NICO: </strong>
          </label>
          <InputText
            id="correo"
            v-model="data_cliente_factura.correo"
            required="true"
          />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_cliente_factura.estado"
            :disabled="!data_cliente_factura.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarClienteFactura"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import TipoDocumentoIdentidadService from "@/service/TipoDocumentoIdentidadService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente_factura: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  clienteService: null,
  tipoDocumentoIdentidadService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_cliente_factura: this.cliente_factura,
      tipo_documento_selected: {},
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tipos_documentos: [],
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
    };
  },
  created() {
    this.clienteService = new ClienteService();
    this.tipoDocumentoIdentidadService = new TipoDocumentoIdentidadService();

    this.cargarTiposDocumentos();
  },
  methods: {
    cargarTiposDocumentos() {
      this.tipoDocumentoIdentidadService
        .getAllTipoDocumentoIdentidad()
        .then((data) => {
          data.forEach((element) => {
            if (
              this.data_cliente_factura.id &&
              this.data_cliente_factura.tipo_documento == element.id
            ) {
              this.tipo_documento_selected = {
                label: element.codigo + " - " + element.descripcion,
                value: element.id,
              };
            } else {
              if (element.id == 1) {
                this.tipo_documento_selected = {
                  label: element.codigo + " - " + element.descripcion,
                  value: element.id,
                };
              }
            }
            this.tipos_documentos.push({
              label: element.codigo + " - " + element.descripcion,
              value: element.id,
            });
          });
        });
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarClienteFactura() {
      if (this.tipo_documento_selected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Seleccione un tipo de documento",
          life: 3000,
        });
        return;
      }
      this.errors = {};
      this.enviado = true;
      //editar cliente_factura
      if (this.cliente_factura.id) {
        let cliente_enviar = {
          ...this.data_cliente_factura,
          tipo_documento: this.tipo_documento_selected.value,
          estado: this.data_cliente_factura.estado.value,
        };
        this.clienteService
          .updatedClienteFactura(cliente_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_cliente_factura;
        this.data_cliente_factura = {
          ...this.data_cliente_factura,
          tipo_documento: this.tipo_documento_selected.value,
          estado: this.data_cliente_factura.estado.value,
        };
        this.clienteService
          .sendClienteFacturaNuevo(this.data_cliente_factura)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_cliente_factura = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });

              this.$emit("actualizarListado", data.cliente_factura);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente_factura(val) {
      this.data_cliente_factura = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>