<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Cliente Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span
            ><strong
              >TIPO CLIENTE: <span class="p-invalid">*</span>
            </strong></span
          >
          <Dropdown
            v-model="data_cliente.tipo"
            :options="clienteList"
            optionLabel="label"
            placeholder="Seleccione un tipo.."
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.tipo">{{
            errors.tipo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong>CLIENTE/FAMILIAR: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            v-model.trim="data_cliente.nombre"
            required="false"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <!---<div class="field col-12 md:col-3">
          <label for="sexo"
            ><strong>SEXO:  </strong></label
          >
          <Dropdown
            id="sexo"
            v-model="data_cliente.sexo"
            :options="sexo"
            optionLabel="label"
            placeholder="Seleccione un sexo..."
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.sexo">{{
            errors.sexo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="fecha_nacimiento"
            ><strong>EDAD: <span class="p-invalid">*</span></strong
            ><strong class="p-invalid">{{ edadMesesCliente }}</strong></label
          >
          <InputText
            id="fecha_nacimiento"
            v-model="data_cliente.fecha_nacimiento"
            type="date"
            :max="fecha_actual"
          />
          <small class="p-invalid" v-if="errors.fecha_nacimiento">{{
            errors.fecha_nacimiento[0]
          }}</small>
        </div>
        -->
        <!-- <div class="field col-12 md:col-4">
          <label for="ci_nit"><strong>TIPO DOCUMENTO: </strong></label>
          <Dropdown
            id="tipo_documento_identidad"
            v-model="tipo_documento_selected"
            :options="tipos_documentos"
            optionLabel="label"
            placeholder="Seleccione tipo documento..."
          >
          </Dropdown>
        </div> -->
        <div class="field col-12 md:col-3">
          <span><strong>CI/NIT: </strong></span>
          <InputText id="ci_nit" v-model="data_cliente.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>TEL&Eacute;FONO: </strong></span>
          <InputText
            v-model="data_cliente.telefono"
            required="true"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>CORREO ELECTR&Oacute;NICO: </strong> </span>
          <InputText v-model="data_cliente.correo" required="true" />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>OBSERVACI&Oacute;N:</strong> </span>
          <Textarea v-model="data_cliente.observacion" rows="1" autoResize />
          <small class="p-invalid" v-if="errors.observacion">{{
            errors.observacion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong>CONTACTOS: </strong> <span class="p-invalid">*</span></span
          >
          <InputText
            v-model.trim="data_cliente.contactos"
            required="false"
            autofocus
          />
          <small class="p-invalid" v-if="errors.contactos">{{
            errors.contactos[0]
          }}</small>
        </div>
        <!--  <div class="field col-12 md:col-1">
          <label for="ci_nit"><strong>EXT. </strong></label>
          <InputText id="ci_nit" v-model="data_cliente.complemento_documento" />
        </div> -->

        <div class="field col-12 md:col-10">
          <span><strong>DOMICILIO: </strong> </span>
          <Textarea
            v-model="data_cliente.domicilio"
            required="true"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.domicilio">{{
            errors.domicilio[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_cliente.estado"
            :disabled="!data_cliente.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="cliente.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="cliente.id ? 'pi pi-refresh' : 'pi pi-save'"
        class="p-button-info p-button-lg"
        @click="guardarCliente"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import TipoDocumentoIdentidadService from "@/service/TipoDocumentoIdentidadService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  clienteService: null,
  tipoDocumentoIdentidadService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_cliente: this.cliente,
      tipo_documento_selected: {},
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tipos_documentos: [],
      fecha_actual: new Date().toISOString().slice(0, 10),
      sexo: [
        { label: "MASCULINO", value: "MASCULINO" },
        { label: "FEMENINO", value: "FEMENINO" },
      ],
      clienteList: [
        { label: "PARTICULAR", value: "PARTICULAR" },
        { label: "FUNERARIA", value: "FUNERARIA" },
      ],
    };
  },
  created() {
    this.clienteService = new ClienteService();
    this.tipoDocumentoIdentidadService = new TipoDocumentoIdentidadService();

    /* this.cargarTiposDocumentos(); */
  },
  computed: {
    edadMesesCliente() {
      if (this.data_cliente.fecha_nacimiento) {
        let fecha_nacimiento = new Date(this.data_cliente.fecha_nacimiento);
        let fecha_actual = new Date();
        let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
        let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
        if (
          meses < 0 ||
          (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
        ) {
          edad--;
          meses += 12;
        }
        return edad + " años y " + meses + " meses";
      }
      return "";
    },
  },
  methods: {
    validarclientevacio() {
      if (this.data_cliente.nombre == "") {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Ingrese un nombre",
          life: 3000,
        });
        this.enviado = false;
        return;
      }
    },
    validartipo_cliente() {
      if (
        this.data_cliente.tipo.value == "FUNERARIA" &&
        !this.data_cliente.contactos
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Ingrese un contacto",
          life: 3000,
        });
        this.enviado = false;
        return true;
      }
    },

    validarClienteParticular() {
      if (
        this.data_cliente.tipo.value == "PARTICULAR" &&
        !this.data_cliente.nombre
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Ingrese un nombre",
          life: 3000,
        });
        this.enviado = false;
        return;
      }
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarCliente() {
      this.errors = {};
      this.enviado = true;
      if (
        this.validarclientevacio() ||
        //this.verificarSoliciante() ||
        this.validartipo_cliente() ||
        this.validarClienteParticular()
      ) {
        return;
      }
      //editar cliente
      if (this.cliente.id) {
        let cliente_enviar = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          tipo_documento: this.tipo_documento_selected.value,
          //  sexo: this.data_cliente.sexo.value,
          tipo: this.data_cliente.tipo.value,
        };
        this.clienteService.updatedCliente(cliente_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarClientes");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO CLIENTE
        let tmp = this.data_cliente;
        this.data_cliente = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          tipo_documento: this.tipo_documento_selected.value,
          //   sexo: this.data_cliente.sexo.value,
          tipo: this.data_cliente.tipo.value,
        };
        this.clienteService.sendClienteNuevo(this.data_cliente).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_cliente = tmp;
          } else {
            this.errors = {};

            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarClientes", data.cliente);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente(val) {
      this.data_cliente = val;
      this.data_cliente.tipo = {
        label: val.tipo == undefined ? "PARTICULAR" : val.tipo,
        value: val.tipo == undefined ? "PARTICULAR" : val.tipo,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
